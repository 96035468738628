import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout'
import GatsbyLink from '../components/GatsbyLink'
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import RelatedPosts from '../components/Related/RelatedPosts';
import { RenderShortcode } from '../components/RenderShortcode';
import { Hero } from '../components/Acf/Hero';
import { ImagePass } from '../components/Image/ImagePass';
import './post.scss';
import { decodeEntities, stripHtmlTags } from '../utils/helpers';
import { dateI18n } from '@wordpress/date';

export const BlogPostTemplate = ({
  content,
  categories,
  title,
  date,
  author,
  blogSlug,
  showAuthor
}) => {
  return (
    <section className="single-post">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="wrap">
              <RenderShortcode content={content} />
            </div>
            {/* {categories && categories.length ? (
              <div className="taxonomy">
                <h4>Categories</h4>
                <ul className="taglist">
                  {categories.map(category => (
                    <li key={`${category.slug}cat`}>
                      {category.slug &&
                        <GatsbyLink to={category.path}>
                          {category.name}
                        </GatsbyLink>
                      }
                    </li>
                  ))}
                </ul>
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    </section>
  )
}

const BlogPost = ( props ) => {
  const { data, location } = props;
  const { wordpressPost: post, site, siteSettings, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings
  const { options = null } = siteSettings
  if (!post) return null;
  const { title, content, excerpt, featured_media, yoast, categories, date, author } = post;
  const { blogSlug, date_format } = wordpressWpSettings;
  const dateFormatted = date && date_format && dateI18n(date_format,date)
  return (
    <Layout
      location={location}
      wordpressUrl={wordpressUrl}
      heroTheme="white"
    >
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={featured_media && featured_media.source_url && featured_media.source_url}
        location={location}
      />
      <Hero heading={title} post />
      <BlogPostTemplate
        content={content}
        categories={categories}
        title={title}
        date={dateFormatted}
        author={author}
        blogSlug={blogSlug}
        showAuthor={siteSettings.options.showAuthor}
      />
      {/* <RelatedPosts /> */}
    </Layout>
  )
}

export default Previewable(BlogPost, 'post');

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    },
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      path
      content
      excerpt
      wordpress_id
      date
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1300, quality: 80) {
              ... GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
        path
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`
